const host = process.env.REACT_APP_BASE_URL;

export const V1_APIS = {
	// Initial
	POST_ANALYTICS: `${host}users/v1/post-analytics/`,

	// Student Dashboard
	STUDENT_DASHBOARD: `${host}student/v1/student-dashboard/`,
	GET_STUDENT_DETAILS: `${host}users/v1/get-student-details/`,
	GET_STUDENT_DETAILS2: `${host}users/v1/get-student-details2/`,
	GET_SCHEDULED_OBJECTIVE_EXAM: `${host}student/v1/get-scheduled-objective-exams/`,
	LIST_OF_SUBJECT_STUDENT: `${host}users/v1/list-of-subject-for-a-student/`,
	GET_STUDENT_ATTENDANCE: `${host}student/v1/get-student-attendance2/`,
	GET_STUDENT_COURSE_LIST: `${host}users/v1/list-of-course-for-a-student/`,

	GET_USER_TYPE: `${host}users/v1/get-user-type/`,
	GET_QUESTION_DETAILS: `${host}student/v1/get-question-details/`,
	GET_PAST_CLASS_CHAPTERS: `${host}student/v1/get-pastclass-chapters/`,
	GET_PAST_LIVE_CLASSES_NOTES: `${host}student/v1/get-past-live-classes_notes/`,
	ALL_EXAM_LIST: `${host}test/v1/all-exam-list/`,
	GET_GIVEN_OBJECTIVE_EXAM: `${host}student/v1/get-given-objective_exams/`,
	GET_STUDENT_CHAPTER_WITH_DPP_COUNT: `${host}student/v1/get-student-chapter-with-dpp-count/`,
	GET_EXAM_INFO: `${host}test/v1/get-exam-info/`,
	START_EXAM: `${host}test/v1/start-exam/`,
	SAVE_EXAM: `${host}test/v1/save-exam-questions/`,
	FETCH_QUESTIONS: `${host}test/v1/fetch-questions/`,
	SUBMIT_EXAM: `${host}test/v1/submit-exam/`,
	GET_ALLOTTED_QUESTIONS: `${host}test/v1/get-student-objective-alloted-questions/`,
	RESET_QUESTION: `${host}test/v1/reset-question/`,
	STUDENT_INSTRUCTION_OBJECTIVE_EXAM: `${host}student/v1/instruction-objective-exam`,
	UPDATE_OBJECTIVE_EXAM_QUESTION_RESPONSE: `${host}student/v1/update-objective-exam-question-response/`,
	GET_PARENT_DETAILS: `${host}users/v1/get-parent-details/`,

	// Pending Documentation
	CHANGE_PASSWORD: `${host}users/v1/change-password/`,
	SUBMIT_OBJECTIVE_EXAM: `${host}student/v1/submit-objective-exam/`,
	GET_STUDENT_PERCENTILE: `${host}student/v1/get-student-percentile/`,
	GET_OBJECTIVE_EXAM_RESULT: `${host}student/v1/get-objective-exam-result/`,
	GET_STUDENT_OBJECTIVE_ALLOTTED_QUESTIONS: `${host}student/v1/get-student-objective-alloted-questions/`,
	STUDENT_DPP_EXAM_STATUS: `${host}test/v1/student-dpp-exam-status/`,
	GET_OBJECTIVE_EXAM_CHAPTERS: `${host}student/v1/get-objective-exam-chapters/`,
	GET_LIVE_CLASS_NOTES: `${host}student/v1/get-live-class-notes/`,
	GET_SCHEDULED_LIVE_CLASSES: `${host}student/v1/get-scheduled-live-classes2/`,
	START_LIVE_CLASS: `${host}student/v1/start-live-class/`,
	UPDATE_STUDENT_EMAIL: `${host}users/v1/update-student-email/`,
	POST_STUDENT_REPORT_REQUEST: `${host}users/v1/post-student-report-request/`,
	SWITCH_COURSE: `${host}users/v1/switch-course/`,
	UPDATE_PARENT_DETAILS: `${host}users/v1/update-parent-details/`,
	GET_STUDENT_INSTALLMENT: `${host}users/v1/get-student-installments/`,
	GET_STUDENT_NOTIFICATION: `${host}student/v1/get-notification/`,
	START_OBJECTIVE_EXAM: `${host}student/v1/start-objective-exam/`,
	NPS_RATING: `${host}student/v1/submit-nps-rating`,
	GET_PAST_LIVE_CLASS_LIST: `${host}student/v1/get-past-live-classe-list/`,
	GET_PAST_LIVE_CLASS_LIST_SECTION: `${host}student/v1/get-past-live-classes/`,
	GET_LIVE_CLASS_ASSIGNMENTS: `${host}student/v1/live-class-assignments/`,

	UPDATE_OBJECTIVE_EXAM_TEST_QUESTION: `${host}student/v1/update-objective-exam-test-question/`,
	POST_PENDING_DOUBT: `${host}student/v1/post-pending-doubt/`,
	UPDATE_STUDENT_QUERY: `${host}student/v1/update-student-query/`,
	GET_SOLUTION: `${host}student/v1/get-solution/`,
	STUDENT_QUERIES_TEXT: `${host}student/v1/student-queries-text/`,
	UPDATE_PENDING_DOUBT: `${host}student/v1/update-pending-doubt/`,
	STUDENT_ZEST_RESULT: `${host}student/v1/zest-result/`,
	GET_VIDEO_LECTURES_SUBJECT: `${host}student/v1/get-video-lectures-subjects/`,
	GET_STUDENT_NOTES: `${host}student/v1/get-student-notes/`,
	GET_CHAPTER_LECTURES_PER_SUBJECT: `${host}student/v1/get-chapter-lectures-per-subject/`,
	GET_PENDING_DOUBTS: `${host}student/v1/get-pending-doubts/`,

	STUDENT_SESSION_CLOSE: `${host}test/v1/student-session-close/`,
	STUDENT_SESSION_RESTART: `${host}test/v1/student-session-restart/`,
	GET_ADAPTIVE_CHAPTERS: `${host}test/v1/get-adaptive-chapters/`,
	STUDENT_SESSION_LIST: `${host}test/v1/student-session-list/`,
	GET_QUESTION_BY_ID: `${host}test/v1/get-question-by-id/`,
	SESSION_REPORT: `${host}test/v1/session-report/`,
	START_ADAPTIVE_EXAM: `${host}test/v1/start-adaptive-exam/`,
	SAVE_AND_NEXT_ADAPTIVE_QUESTIONS: `${host}test/v1/save-and-next-adaptive-exam_question/`,
	EXAM_LIST: `${host}test/v1/exam-list/`,
	GET_EXAM_REPORT: `${host}test/v1/get-exam-report/`,
	GET_DPP_SCHEDULE_LIST: `${host}test/v1/get-dpp-schedule-list/`,
	DPP_EXAM_BY_LIVE_CLASS: `${host}test/v1/ddp-exam-by-liveclass/`,
	SAVE_STUDENT_EXAM_QUESTION: `${host}test/v1/save-student-exam-question/`,

	GET_SUBJECTS: `${host}support/v1/get-subjects/`,
	GET_STUDY_MATERIAL: `${host}support/v1/get-study-material/`,
	GET_SUBJECT_CLASS_BINDING: `${host}support/v1/get-student-subject-class-binding/`,
	GET_CHAPTERS: `${host}support/v1/get-chapters/`,
	GET_TEACHER_NOTES: `${host}support/v1/get-teacher-notes/`,
	GET_TIMETABLE_PDF: `${host}support/v1/get-timetable-pdf`,
	GET_DOUBT_MODULE_CHAPTERS: `${host}support/v1/get-doubt-module-chapters/`,
	GET_DOUBT_MODULE_QUESTIONS: `${host}support/v1/get-doubt-module-questions/`,
	GET_SUPPORT_BOOKS_INFO: `${host}support/v1/get-books-info/`,
};

const DASHBOARD_V1_APIS = {
	// Student Dashboard
	ALL_EXAM_LIST: `${host}test/v1/all-exam-list/`,
	ALL_EXAM_LIST_V2: `${host}test/v2/all-exam-list/`,
	GET_SCHEDULED_OBJECTIVE_EXAM: `${host}student/v1/get-scheduled-objective-exams/`,
	GET_STUDENT_ATTENDANCE: `${host}student/v1/get-student-attendance2/`,
	GET_STUDENT_ATTENDANCE_DATES: `${host}student/get-student-attendance-dates/`,
	GET_STUDENT_COURSE_LIST: `${host}users/v1/list-of-course-for-a-student/`,
	GET_STUDENT_DETAILS: `${host}users/v1/get-student-details/`,
	GET_STUDENT_DETAILS2: `${host}users/v1/get-student-details2/`,
	LIST_OF_SUBJECT_STUDENT: `${host}users/v1/list-of-subject-for-a-student/`,
	STUDENT_DASHBOARD: `${host}student/v1/student-dashboard/`,
};

const PRACTICE_SESSION_V1_APIS = {
	// Practice session
	GET_ADAPTIVE_CHAPTERS: `${host}test/v1/get-adaptive-chapters/`,
	GET_QUESTION_BY_ID: `${host}test/v1/get-question-by-id/`,
	GET_SUBJECTS: `${host}support/v1/get-subjects/`,
	SAVE_AND_NEXT_ADAPTIVE_QUESTIONS: `${host}test/v1/save-and-next-adaptive-exam_question/`,
	SESSION_REPORT: `${host}test/v1/session-report/`,
	START_ADAPTIVE_EXAM: `${host}test/v1/start-adaptive-exam/`,
	STUDENT_SESSION_CLOSE: `${host}test/v1/student-session-close/`,
	STUDENT_SESSION_LIST: `${host}test/v1/student-session-list/`,
	STUDENT_SESSION_RESTART: `${host}test/v1/student-session-restart/`,
};

//eslint-disable-next-line
const ApiUrl = {
	ENV: process.env.REACT_APP_ENV,
	LOGIN: `${host}login/`,
	GET_VERSION: `${host}support/get-version/?platform=akmc_web`,
	FORGOT_PASSWORD: `${host}forgot-password-request-akmc/`,
	CREATE_STUDENT: `${host}users/create-student/`,
	SWITCH_USER: `${host}users/switch-user/`,

	PAYMENT_PROCEED_TO_PAY: `${host}payment/proceed-to-pay/`,
	VERIFY_PAYMENT: `${host}payment/verify-payment/`,
	GET_CHECKSUM: `${host}payment/get-checksum/`,
	COLLECT_PAYMENT_SUPPORT: `${host}payment/collect-payment-support/`,
	COLLECT_PAYMENT_SUPPORT_HANDLE: `${host}payment/collect-payment-support-handle/`,
	GET_ORDER_DETAILS: `${host}payment/get-order-details/`,
	GET_SECTION_INSTALLMENT_PLANS: `${host}payment/get-section-installment-plans/`,

	// GET_STUDENT_DETAILS_STUDENTID: `${host}users/get-student-details/`,
	// GET_STUDY_MATERIAL: `${host}support/get-study-material/`,
	// STUDENT_SESSION_CLOSE: `${host}test/student-session-close/`,
	// STUDENT_SESSION_RESTART: `${host}test/student-session-restart/`,
	// GET_SUBJECTS: `${host}support/get-subjects/`,
	// GET_ADAPTIVE_CHAPTERS: `${host}test/get-adaptive-chapters/`,
	// STUDENT_SESSION_LIST: `${host}test/student-session-list/`,
	// GET_QUESTION_BY_ID: `${host}test/get-question-by-id/`,
	// SESSION_REPORT: `${host}test/session-report/`,
	// GET_SUBJECT_CLASS_BINDING: `${host}support/get-student-subject-class-binding/`,
	// START_ADAPTIVE_EXAM: `${host}test/start-adaptive-exam/`,
	// SAVE_AND_NEXT_ADAPTIVE_QUESTIONS: `${host}test/save-and-next-adaptive-exam_question/`,
	// GET_CHAPTERS: `${host}support/get-chapters/`,
	// GET_TEACHER_NOTES: `${host}support/get-teacher-notes/`,
	// GET_TIMETABLE_PDF: `${host}support/get-timetable-pdf`,
	// ALL_EXAM_LIST: `${host}test/all-exam-list/`,
	// EXAM_LIST: `${host}test/exam-list/`,
	// GET_EXAM_REPORT: `${host}test/get-exam-report/`,
	// GET_SUBJECTS_CLASS: `${host}support/get-subjects/`,
	// GET_DPP_SCHEDULE_LIST: `${host}test/get-dpp-schedule-list/`,
	// GET_DOUBT_MODULE_CHAPTERS: `${host}support/get-doubt-module-chapters/`,
	// GET_DOUBT_MODULE_QUESTIONS: `${host}support/get-doubt-module-questions/`,
	// GET_SUPPORT_BOOKS_INFO: `${host}support/get-books-info/`,
	// DPP_EXAM_BY_LIVE_CLASS: `${host}test/ddp-exam-by-liveclass/`,
	// SAVE_STUDENT_EXAM_QUESTION: `${host}test/save-student-exam-question/`,

	CHANGE_PASSWORD: `${host}users/v1/change-password/`,
	// GET_PARENT_DETAILS: `${host}users/v1/get-parent-details/`,
	UPDATE_STUDENT_EMAIL: `${host}users/v1/update-student-email/`,
	STUDENT_INSTRUCTION_OBJECTIVE_EXAM: `${host}student/v1/instruction-objective-exam`,
	START_OBJECTIVE_EXAM: `${host}student/v1/start-objective-exam/`,
	SUBMIT_OBJECTIVE_EXAM: `${host}student/v1/submit-objective-exam/`,
	POST_ANALYTICS: `${host}users/v1/post-analytics/`,
	POST_STUDENT_REPORT_REQUEST: `${host}users/v1/post-student-report-request/`,
	NPS_RATING: `${host}student/v1/submit-nps-rating`,
	SWITCH_COURSE: `${host}users/v1/switch-course/`,
	START_EXAM: `${host}test/v1/start-exam/`,
	SAVE_EXAM: `${host}test/v1/save-exam-questions/`,
	SUBMIT_EXAM: `${host}test/v1/submit-exam/`,
	RESET_QUESTION: `${host}test/v1/reset-question/`,
	GET_EXAM_INFO: `${host}test/v1/get-exam-info/`,
	GET_LIVE_CLASS_NOTES: `${host}student/v1/get-live-class-notes/`,
	GET_SCHEDULED_LIVE_CLASSES: `${host}student/v1/get-scheduled-live-classes2/`,
	GET_PAST_LIVE_CLASSES_NOTES: `${host}student/v1/get-past-live-classes_notes/`,
	GET_PAST_CLASS_CHAPTERS: `${host}student/v1/get-pastclass-chapters/`,
	GET_STUDENT_NOTIFICATION: `${host}student/v1/get-notification/`,
	GET_OBJECTIVE_EXAM_CHAPTERS: `${host}student/v1/get-objective-exam-chapters/`,
	GET_STUDENT_OBJECTIVE_ALLOTTED_QUESTIONS: `${host}student/v1/get-student-objective-alloted-questions/`,
	GET_PAST_LIVE_CLASS_LIST: `${host}student/v1/get-past-live-classe-list/`,
	GET_PAST_LIVE_CLASS_LIST_SECTION: `${host}student/v1/get-past-live-classes/`,
	GET_LIVE_CLASS_ASSIGNMENTS: `${host}student/v1/live-class-assignments/`,
	// UPDATE_PARENT_DETAILS: `${host}users/v1/update-parent-details/`,
	FETCH_QUESTIONS: `${host}test/v1/fetch-questions/`,
	GET_STUDENT_INSTALLMENT: `${host}users/v1/get-student-installments/`,
	GET_ALLOTTED_QUESTIONS: `${host}test/v1/get-student-objective-alloted-questions/`,
	STUDENT_DPP_EXAM_STATUS: `${host}test/v1/student-dpp-exam-status/`,
	GET_USER_TYPE: `${host}users/v1/get-user-type/`,
	UPDATE_OBJECTIVE_EXAM_TEST_QUESTION: `${host}student/v1/update-objective-exam-test-question/`,
	POST_PENDING_DOUBT: `${host}student/v1/post-pending-doubt/`,
	UPDATE_STUDENT_QUERY: `${host}student/v1/update-student-query/`,
	GET_SOLUTION: `${host}student/v1/get-solution/`,
	STUDENT_QUERIES_TEXT: `${host}student/v1/student-queries-text/`,
	UPDATE_PENDING_DOUBT: `${host}student/v1/update-pending-doubt/`,
	START_LIVE_CLASS: `${host}student/v1/start-live-class/`,
	GET_GIVEN_OBJECTIVE_EXAM: `${host}student/v1/get-given-objective_exams/`,
	STUDENT_ZEST_RESULT: `${host}student/v1/zest-result/`,
	UPDATE_OBJECTIVE_EXAM_QUESTION_RESPONSE: `${host}student/v1/update-objective-exam-question-response/`,
	GET_VIDEO_LECTURES_SUBJECT: `${host}student/v1/get-video-lectures-subjects/`,
	GET_STUDENT_NOTES: `${host}student/v1/get-student-notes/`,
	GET_CHAPTER_LECTURES_PER_SUBJECT: `${host}student/v1/get-chapter-lectures-per-subject/`,
	GET_PENDING_DOUBTS: `${host}student/v1/get-pending-doubts/`,
	// GET_QUESTION_DETAILS: `${host}student/v1/get-question-details/`,
	GET_OBJECTIVE_EXAM_RESULT: `${host}student/v1/get-objective-exam-result/`,
	GET_STUDENT_PERCENTILE: `${host}student/v1/get-student-percentile/`,

	// Profile
	GET_PARENT_DETAILS: `${host}users/v1/get-parent-details/`,
	UPDATE_PARENT_DETAILS: `${host}users/v1/update-parent-details/`,

	// Assignments
	GET_DPP_SCHEDULE_LIST: `${host}test/v1/get-dpp-schedule-list/`,
	GET_STUDENT_CHAPTER_WITH_DPP_COUNT: `${host}student/v1/get-student-chapter-with-dpp-count/`,

	// Doubt module - Search
	GET_QUESTION_DETAILS: `${host}student/v1/get-question-details/`,

	...DASHBOARD_V1_APIS,
	...PRACTICE_SESSION_V1_APIS,

	// /student/StudyMaterialSubject
	GET_SUBJECT_CLASS_BINDING: `${host}support/v1/get-student-subject-class-binding/`,
	GET_STUDY_MATERIAL: `${host}support/v1/get-study-material/`,

	GET_CHAPTERS: `${host}support/v1/get-chapters/`,
	GET_TEACHER_NOTES: `${host}support/v1/get-teacher-notes/`,
	GET_TIMETABLE_PDF: `${host}support/v1/get-timetable-pdf`,
	EXAM_LIST: `${host}test/v1/exam-list/`,
	GET_EXAM_REPORT: `${host}test/v1/get-exam-report/`,
	GET_DOUBT_MODULE_CHAPTERS: `${host}support/v1/get-doubt-module-chapters/`,
	GET_DOUBT_MODULE_QUESTIONS: `${host}support/v1/get-doubt-module-questions/`,
	GET_SUPPORT_BOOKS_INFO: `${host}support/v1/get-books-info/`,
	DPP_EXAM_BY_LIVE_CLASS: `${host}test/v1/ddp-exam-by-liveclass/`,
	SAVE_STUDENT_EXAM_QUESTION: `${host}test/v1/save-student-exam-question/`,

	PAYMENT_DUE: `${host}student/v1/payment-due`,
	CHECK_LOCKED: `${host}student/v1/check-locked`,

	// Help and support
	POST_FEEDBACK: `${host}support/post-feedback/`,

	// DPP LISTING
	GET_STUDENT_SUBJECTS: `${host}test/v1/get-student-subjects`,
	GET_STUDENT_DPPS: `${host}test/v1/get-student-dpps`,
	GET_STUDENT_CHAPTER: `${host}test/get-student-chapter`,

	// ----------------------------------------------------------------------------------
	// Generic Offline
	GET_EXAM_INFO_V2: `${host}test/v1/fetch-instructions`,
	FETCH_ALL_QUESTIONS: `${host}test/v1/fetch-all-questions`,
	START_EXAM_V2: `${host}test/v2/start-exam`,
	BULK_SAVE_ANSWERS: `${host}test/v1/bulk-save-questions`,
	TEST_NTP: `${host}test/v1/ntp`,

	// ----------------------------------------------------------------------------------
	// New Signup Flow
	REGISTER_STUDENT: `${host}users/v1/register`,
	VERIFY_OTP: `${host}users/v1/verify-otp`,
	ONBOARD_STUDENT: `${host}users/v1/onboarding`,
	REVERSE_GEO_CODING: "https://api.bigdatacloud.net/data/reverse-geocode-client",

	// ----------------------------------------------------------------------------------
	// Exam Result
	GET_STUDENT_SCHOOL_RESULTS: `${host}test/v1/get-student-school-results/`,
	GET_STUDENT_FILTERED_REPORT: `${host}test/v1/get-student-filtered-report/`,
	GET_STUDENT_EXAM_QUESTION_STATUS: `${host}test/v1/get-student-exam-question-status/`,
	// ----------------------------------------------------------------------------------

	//DASHBOARD

	GET_DASHBOARD_CLASSES: `${host}student/v1/dashboard/classes`,
	REQUEST_CALL: `${host}student/request-call`,
	GET_BANNER_DETAILS: `${host}student/banner`,
	ENROLL_COURSE: `${host}student/enroll-course`,
	COMPLETE_COURSE_PAYMENT: `${host}student/enroll-course/complete-payment`,
	FAILED_COURSE_PAYMENT: `${host}student/enroll-course/payment-failed`,
	GET_CITY: `${host}users/v1/get-city`,
	GET_CORNER_FILTERS: `${host}ncert/meta`,
	GET_CHAPTER_VIDEOS: `${host}ncert/v1/get-ncert-video-base-on-chapter/`,
	GET_CHAPTER_NOTES: `${host}ncert/v1/get-ncert-notes-base-on-chapter/`,
	GET_DASHBOARD_CORNER: `${host}ncert/dashboard`,
	GET_CORNER_DETAILS: `${host}ncert/detail`,
	GET_NCERT_EXERCISE: `${host}ncert/v1/get-ncert-exercise-base-on-chapter/`,
	GET_NCERT_QUESTIONS: `${host}ncert/v1/get-ncert-question-base-on-exercise/`,
};

export default ApiUrl;
