import React,{useState, useEffect} from "react";
import AttendanceMark from "../../../assets/img/attendance_mark.svg";
import SolidButton from "../SolidButton";
import { AttendanceDummyData, SubjectDummyData } from "../../../Student/dashboard/dummyData";
import lockedImg from "../../../assets/img/lockedImg.svg";
import Api from "../../../common/Api";
import { segmentEvents } from "../../../utils/constants";
import Styles from "./attendance.module.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Button from 'react-bootstrap/Button';

 

const Attendance = ({ attendance, studentSubjects, isFree, requestACall, hideTitle, attendanceDates=[] }) => {
	const data = isFree ? AttendanceDummyData : attendance;
	const subjectsList = isFree ? SubjectDummyData : studentSubjects;
	
	const [mark, setMark] = useState([]);

	useEffect(() => {
		
		let t_attendance_dates = attendanceDates.map((item) => {
			return item.split("T")[0];
		})
		setMark(t_attendance_dates);
	}, [])
     
	const [attendanceState, setAttendanceState] = useState({
		isCalendarVisible:false
	})
	if(attendanceState.isCalendarVisible){
		return(
			<div>
				
				<Button variant="primary" style={{width: "100%", marginBottom:"15px"}} onClick={(e) => {
					setAttendanceState({
						...attendanceState,
						isCalendarVisible: false
					})
				}} size="lg">Switch to Classic View</Button>{' '}
				
			
				<Calendar tileClassName={({ date, view }) => {
					const d = new Date(date);
					let iterDate = d.getFullYear()+'-'+("0"+(d.getMonth()+1)).slice(-2)+'-'+("0"+d.getDate()).slice(-2);
					if(mark.includes(iterDate)){
						return 'react-calendar__tile--now-highlighted';
					}
				}}
				
				/>
			</div>
		)
	}




	return (
		<div>

				<Button variant="success" style={{width: "100%", marginBottom:"15px"}} onClick={(e) => {
					setAttendanceState({
						...attendanceState,
						isCalendarVisible: true
					})
				}} size="lg">Switch to Calendar View</Button>{' '}

			{!hideTitle && <p className={Styles.attendance_header_text}>Attendance</p>}
			<div className={Styles.attendance_mark_wrapper}>
				<p className={Styles.attendance_mark_text1}>{data.attendance_count}</p>

				<div className={Styles.attendance_divider} />

				<p className={Styles.attendance_mark_text2}>{data.total_classes}</p>
				<img src={AttendanceMark} alt="mark" className={Styles.attendance_mark_image} />
			</div>
			{data.Subjects.map((subject, i) => {
				const bgColor = subjectsList.find((obj) => obj.subject_assoc.subject_name === subject.subject_name)?.subject_assoc
					?.primary_color;

				return (
					<div
						className={Styles.dashboard_attendance_card}
						style={{
							"--attendance-card": bgColor ? bgColor : "#E0FAFF",
						}}
						key={i}>
						<p className={Styles.dashboard_attendance_text1}>{subject.subject_name}</p>

						<p className={Styles.dashboard_attendance_text2}>
							<span className={Styles.dashboard_attendance_text3}>{subject.subject_attendance}</span> out of{" "}
							{subject.subject_total_attendance}
						</p>
					</div>
				);
			})}
			{isFree && (
				<div className={Styles.dashboard_blur}>
					<div>
						<img src={lockedImg} alt="" />
						<br />
						Connect with us to unlock attendance.
						<br />
						<SolidButton
							small
							style={{
								marginTop: "1rem",
								width: 150,
							}}
							onClick={() => {
								Api.trackEvent(segmentEvents.FREEDASHBOARD.REQUESTCALL_ATTENDANCE);
								requestACall("Attendance");
							}}>
							Request a call
						</SolidButton>
					</div>
				</div>
			)}
		</div>
	);
};

export default Attendance;
