import { SET_DASHBOARD_STATE, SET_DASHBOARD_SELECTED_CLASS, SET_DASHBOARD_SELECTED_SUBJECT } from "../actionType";

export const initialState = {
	exams: [],
	dpps: [],
	attendance: {},
	attendanceDates:[],
	banner: {},
	courses: [],
	classes: [],
	loadingExams: true,
	loadingDpps: true,
	loadingAttendance: true,
	loadingAttendanceDates:true,
	loadingBanner: true,
	loadingCourses: true,
	loadingClasses: true,
	attendanceLoaded: false,
	attendanceDatesLoaded:false,
	bannerLoaded: false,
	jee: { classes: [], subjects: [], loading: true, loaded: false },
	neet: { classes: [], subjects: [], loading: true, loaded: false },
	ncert: { classes: [], subjects: [], loading: true, loaded: false },
	selectedClass: { jee: null, neet: null, ncert: null },
	selectedSubject: { jee: null, neet: null, ncert: null },
};

export default function dashboardReducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_DASHBOARD_STATE:
			return { ...state, ...payload };
		case SET_DASHBOARD_SELECTED_CLASS:
			return { ...state, selectedClass: { ...state.selectedClass, ...payload } };
		case SET_DASHBOARD_SELECTED_SUBJECT:
			return { ...state, selectedSubject: { ...state.selectedSubject, ...payload } };
		default:
			return state;
	}
}
