import React, { useEffect, useState } from "react";
import Styles from "./layout.module.css";
import { Link, useHistory } from "react-router-dom";
import myclassroom_logo from "../../assets/img/logo.svg";
import NotificationImg from "../../assets/img/notification.svg";
import HamburgerImg from "../../assets/img/hamburger.svg";
import "./layout.css";
import Footer from "./footer";
import Sidedrawer from "./sidedrawer";
import BatchPopup from "./batchPopup";
import { useDispatch, useSelector } from "react-redux";
import {
	getAttendence,
	getCourses,
	getDashboardBannerDetails,
	getDashboardClasses,
	getDashboardDpps,
	getDashboardExams,
	getStudentDetails,
	getCalendarWiseAttendence
} from "../../store/actions";
import NavLinks from "./navLinks";
import Loader from "../Loader";
import lockedImg from "../../assets/img/lockedImg.svg";
import { MdChevronLeft } from "react-icons/md";
import Api from "../../common/Api";
import { segmentEvents } from "../../utils/constants";

const Layout = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const dashboard = useSelector((state) => state.dashboard);
	let data = localStorage.getItem("userdetail");
	data = data && JSON.parse(data);
	const student = data?.username;
	const { student_data: studentData } = useSelector((state) => state.StudentDetail?.StudentDetail ?? {});

	const [openSidebar, setOpenSidebar] = useState(false);

	useEffect(() => {
		if (!studentData) dispatch(getStudentDetails(student));
		if (!dashboard.bannerLoaded) dispatch(getDashboardBannerDetails());
		if (dashboard.classes.length === 0) dispatch(getDashboardClasses());
		if (dashboard.dpps.length === 0) dispatch(getDashboardDpps());
	}, []);

	useEffect(() => {
		if (!studentData) return;
		if (!dashboard.attendanceLoaded) {
			dispatch(getAttendence(studentData.student_course_id));
		}
		if(!dashboard.attendaceDatesLoaded){
			dispatch(getCalendarWiseAttendence())
		}
		if (dashboard.exams.length === 0) dispatch(getDashboardExams(studentData.id));
		if (dashboard.courses.length === 0) dispatch(getCourses(studentData.id));
	}, [studentData]);

	const showDrawer = () => {
		setOpenSidebar(true);
	};

	const onClose = () => {
		setOpenSidebar(false);
	};

	const [modalOpen, setModalOpen] = useState(false);

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const openNotification = () => {
		Api.trackEvent(segmentEvents.NAVBAR.NOTIFICATIONS_CLICK);
		history.push({
			pathname: "/student/notes/notifications",
			state: { StudentId: studentData.id },
		});
	};

	const redirectAttendance = () => {
		Api.trackEvent(segmentEvents.NAVBAR.RESPONSIVE_ATTENDANCE_CLICK);
		if (studentData.is_free) return;
		history.push({
			pathname: "/student/attendance",
		});
	};

	const loading =
		dashboard.loadingAttendance ||
		dashboard.loadingDpps ||
		dashboard.loadingExams ||
		dashboard.loadingCourses ||
		dashboard.loadingClasses ||
		!studentData;

	return (
		<>
			<div style={{ backgroundColor: "#F4F4F4", display: "flex", flexDirection: "column", minHeight: "100vh" }}>
				{!props.navigationHeader ? (
					<nav className={Styles.navbar}>
						<div className="container-fluid layout_container">
							<div className="navbar-header">
								<div className="d-flex align-items-center">
									<div className="navbar-hamburger" onClick={showDrawer}>
										<img src={HamburgerImg} alt="hamburger" />
									</div>
									<div className="nav_heading" style={{ maxWidth: "310px" }}>
										<Link to="/student">
											<img src={myclassroom_logo} className="myclassroom_logo" alt="logo" />
										</Link>
									</div>
								</div>

								{!dashboard.loadingAttendance && (
									<div className="nav-mobile-wrapper">
										<div className="nav-attendance-mobile" onClick={redirectAttendance}>
											<p className="nav-attendance-mobile-text1">Attendance</p>
											{studentData.is_free ? (
												<div className="locked-container">
													<img src={lockedImg} alt="" style={{ width: 14 }} />
												</div>
											) : (
												<div className="nav-attendance-mobile-text2-wrapper">
													<p className="nav-attendance-mobile-text2">
														{dashboard.attendance.attendance_count}/
														{dashboard.attendance.total_classes}
													</p>
												</div>
											)}
										</div>

										<div className="nav-notification-mobile">
											<img src={NotificationImg} alt="notification" onClick={openNotification} />
										</div>
									</div>
								)}
							</div>

							{studentData && !dashboard.loadingCourses && (
								<NavLinks
									handleModalOpen={handleModalOpen}
									studentData={studentData}
									openNotification={openNotification}
									isFree={studentData.is_free}
								/>
							)}
						</div>
					</nav>
				) : (
					<div className={Styles.navigationHeader}>
						<MdChevronLeft
							fontSize="24px"
							style={{ marginRight: 10, cursor: "pointer" }}
							onClick={() => {
								history.goBack();
							}}
						/>
						{props.title}
					</div>
				)}
				<div style={{ flexGrow: 1, position: "relative", paddingBottom: 30 }}>
					{loading ? (
						<div className={Styles.loaderContainer}>
							<Loader />
						</div>
					) : (
						props.children
					)}
				</div>
				{!props.noFooter && <Footer />}
			</div>
			{studentData && !dashboard.loadingCourses && (
				<Sidedrawer
					openSidebar={openSidebar}
					onClose={onClose}
					courses={dashboard.courses}
					studentData={studentData}
					handleModalOpen={handleModalOpen}
					isFree={studentData.is_free}
				/>
			)}
			<BatchPopup open={modalOpen} handleClose={handleModalClose} courses={dashboard.courses} studentData={studentData} />
		</>
	);
};

export default Layout;
